import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { Link } from "gatsby";

const IndexPage = () => (
    <Layout>
        <Seo title="Home"/>
        <p>A Kickass Presbyterian (<Link to={"/about"}>Matthew Johnston</Link>) on politics, culture, and the church.</p>
        <p>There's not much here yet, but give it some time. At some point I'll make the homepage fancier.</p>
        <p>Check the <Link to="/blog">blog</Link>, I think there's some stuff there.</p>
    </Layout>
);

export default IndexPage;
